import React, { Component } from "react";
import { Link } from "react-router-dom";

class BannerV8 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__slider-area ltn__slider-6 mb-120---">
        <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
          {/* ltn__slide-item */}
          <div
            className="ltn__slide-item--- ltn__slide-item-9 section-bg-1 bg-image"
            style={{
              backgroundImage: `url(${
                publicUrl + "assets/img/slider/home.jpg"
              })`,
            }}
            data-bs-bg={publicUrl + "assets/img/slider/home.jpg"}
          >
            <div className="ltn__slide-item-inner">
              <div className="slide-item-info bg-overlay-white-90 text-center">
                <div className="slide-item-info-inner">
                  <h1 className="slide-title text-uppercase">
                    VAŠE NEKRETNINE, NAŠA BRIGA
                  </h1>
                  <div className="btn-wrapper go-top">
                    <Link
                      to="/services"
                      className="theme-btn-1 btn btn-effect-1 text-uppercase"
                    >
                      Usluge
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </div>
    );
  }
}

export default BannerV8;
