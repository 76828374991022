import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeV9 from "./components/home-v9";
import About from "./components/about";
import TeamDetails from "./components/team-details";
import Error from "./components/404";
import Contact from "./components/contact";
import NotFound from "./components/404";
import ScrollToTop from "./components/ScrollToTop";

class Root extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={HomeV9} />
          <Route path="/services" component={About} />
          <Route path="/about" component={TeamDetails} />
          <Route path="/404" component={Error} />
          <Route path="/contact" component={Contact} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("quarter"));
