import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class TeamDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__team-details-area mb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__team-details-member-info-details">
                <hr />
                <div className="row ltn__custom-gutter mt-50 mb-20">
                  <div className="col-xl-4 col-sm-6 col-12 go-top">
                    <div className="ltn__feature-item ltn__feature-item-6 text-center">
                      <div className="ltn__feature-icon">
                        <span>
                          <i className="icon-tire" />
                        </span>
                      </div>
                      <div className="ltn__feature-info">
                        <h4>
                          <Link to="/service">Home Buying</Link>
                        </h4>
                        <p>
                          Lorem ipsum dolor sit ame it, consectetur adipisicing
                          elit, sed do eiusmod te mp or
                        </p>
                        {/* <a class="ltn__service-btn" href="service.html">Read More</a> */}
                        <Link
                          className="ltn__service-btn"
                          to="/service-details"
                        >
                          Read More <i className="flaticon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-sm-6 col-12">
                    <div className="ltn__feature-item ltn__feature-item-6 text-center active">
                      <div className="ltn__feature-icon">
                        <span>
                          <i className="icon-mechanic" />
                        </span>
                      </div>
                      <div className="ltn__feature-info go-top">
                        <h4>
                          <Link to="/team">Home Selling</Link>
                        </h4>
                        <p>
                          Lorem ipsum dolor sit ame it, consectetur adipisicing
                          elit, sed do eiusmod te mp or
                        </p>
                        {/* <a class="ltn__service-btn" href="service.html">Read More</a> */}
                        <Link
                          className="ltn__service-btn"
                          to="/service-details"
                        >
                          Read More <i className="flaticon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-sm-6 col-12">
                    <div className="ltn__feature-item ltn__feature-item-6 text-center">
                      <div className="ltn__feature-icon">
                        <span>
                          <i className="icon-wheel-1" />
                        </span>
                      </div>
                      <div className="ltn__feature-info go-top">
                        <h4>
                          <Link to="/service-details">Escrow Services</Link>
                        </h4>
                        <p>
                          Lorem ipsum dolor sit ame it, consectetur adipisicing
                          elit, sed do eiusmod te mp or
                        </p>
                        {/* <a class="ltn__service-btn" href="service.html">Read More</a> */}
                        <Link
                          className="ltn__service-btn"
                          to="/service-details"
                        >
                          Read More <i className="flaticon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum. Sed ut perspiciatis unde omnis
                  iste natus error sit voluptatem accusantium doloremque
                  laudantium, totam rem aperiam, eaque ipsa quae ab illo
                  inventore veritatis et quasi architecto beatae vitae dicta
                  sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                  aspernatur aut odit aut fugit, sed quia consequu ntur magni
                  dolores eos qui ratione voluptatem sequi nesciunt. Neque porro
                  quisquam est, qui dolorem ipsum quia dolor sit amet,
                  consectetur, adipisci velit, sed quia non numquam eius modi
                  tempora incidunt ut labore et dolore magnam aliquam quaerat
                  voluptatem.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamDetails;
