import React, { Component } from "react";

class Social extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__social-media">
        <ul>
          {/* <li>
            <a href="https://www.facebook.com/tuna-theme" title="Facebook">
              <i className="fab fa-facebook-f" />
            </a>
          </li>
          <li>
            <a href="https://www.twitter.com/tuna-theme" title="Twitter">
              <i className="fab fa-twitter" />
            </a>
          </li> */}
          <li>
            <a href="https://www.instagram.com/becalmcompany" title="Instagram">
              <i className="fab fa-instagram" />
              <p style={{ display: "inline-block", paddingLeft: "12px" }}>
                becalmcompany
              </p>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Social;
