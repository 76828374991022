import { useEffect } from "react";
import { useHistory } from "react-router-dom";

function ScrollToTop() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
}

export default ScrollToTop;
