import React from "react";
import Navbar from "./global-components/navbar-v2";
import PageHeader from "./global-components/page-header";
import TeamDetails from "./section-components/team-details";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import Aboutv5 from "./section-components/about-v5";

const Team_Details = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="O nama" subheaderTitle={"O nama"} />
      <Aboutv5 inAboutPage={true} />
      {/* <TeamDetails /> */}
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default Team_Details;
