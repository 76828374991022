import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class FaqV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__faq-area mt-30 mb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__faq-inner ltn__faq-inner-2">
                <div id="accordion_2">
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-1"
                      aria-expanded="false"
                    >
                      A paket osnovnih usluga
                    </h6>
                    <div
                      id="faq-item-2-1"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <ul>
                          <li>
                            čuvanje ključa u sertifikovanom sefu (po pismenom
                            nalogu vlasnika, ključ može biti dostupan
                            prijateljima, gostima i rođacima)
                          </li>
                          <li>
                            obilazak i pregled nekretnine iznutra i izvana dva
                            puta mjesečno
                          </li>
                          <li>
                            provjera ispravnosti kućnih aparata i bijele tehnike
                          </li>
                          <li>provjetravanje prostorija</li>
                          <li>
                            kontrola vodovodnih cijevi (da bi se spriječio
                            neugodni miris)
                          </li>
                          <li>prijem pošte</li>
                          <li>
                            podnošenje vlasniku mjesečnog izvještaja o stanju
                            objekta putem e-maila, uz priloženu foto
                            dokumentaciju
                          </li>
                          <li>
                            prijavljivanje vlasniku eventualna oštećenja ili
                            bilo kakav uočeni problem
                          </li>
                          <ul>
                            <li>
                              <strong>Stan/apartman - 30€ mjesečno</strong>
                            </li>
                            <li>
                              <strong>Kuća - 35€ mjesečno</strong>
                            </li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-2"
                      aria-expanded="true"
                    >
                      B premium paket usluga
                    </h6>
                    <div
                      id="faq-item-2-2"
                      className="collapse show"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <ul>
                          <li>
                            čuvanje ključa u digitalnom sefu (po pismenom nalogu
                            vlasnika, ključ može biti dostupan prijateljima,
                            gostima i rođacima)
                          </li>
                          <li>
                            obilazak i pregled nekretnine iznutra i izvana dva
                            puta mjesečno
                          </li>
                          <li>
                            provjera ispravnosti kućnih aparata i bijele tehnike
                          </li>
                          <li>provjetravanje prostorija</li>
                          <li>
                            kontrola vodovodnih cijevi (da bi se spriječio
                            neugodni miris)
                          </li>
                          <li>prijem pošte</li>
                          <li>
                            podnošenje vlasniku mjesečnog izvještaja o stanju
                            nekretnine putem e-maila, uz priloženu foto
                            dokumentaciju
                          </li>
                          <li>
                            prijavljivanje vlasniku eventualna oštećenja ili
                            bilo kakav uočeni problem
                          </li>
                          <li>
                            odžavanje slavina i vodovodnih cijevi, ulivanje
                            tečnosti protiv smrzavanja u sifon i wc šolju
                          </li>
                          <li>
                            posjeta institucijama lokalne samouprave u vezi s
                            posjedom i nekretninom (grad, opština, katastar,
                            komunalno preduzeće, EPCG, vodovod)
                          </li>
                          <li>
                            informisanje vlasnika o svim relevantnim dešavanjima
                            odnosno, aktivnostima, tekućim radovima, sanacijama,
                            kvarovima u naselju ili mjesnoj zajednici
                          </li>
                          <li>
                            očitavanje mjerila (voda i struja), prikupljanje
                            pošte i računa, te njihovo plaćanje/prosljeđivanje
                            vlasniku (ovisno o dogovoru)
                          </li>
                          <li>
                            provjera svih bravarija (prozori i balkonska vrata)
                          </li>
                          <li>
                            generalno čišćenje neposredno prije dolaska vlasnika
                            2 puta godišnje
                          </li>
                          <li>
                            paket dobrodošlice, stavljanje u funkciju grijanja
                            ili hlađenja prije dolaska vlasnika 2 puta godišnje
                          </li>
                          <li>zalivanje cvijeća</li>
                          <ul>
                            <li>
                              <strong>Stan/apartman - 40€ mjesečno</strong>
                            </li>
                            <li>
                              <strong>Kuća - 45€ mjesečno</strong>
                            </li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-3"
                      aria-expanded="false"
                    >
                      C key paket usluga
                    </h6>
                    <div
                      id="faq-item-2-3"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <ul>
                          <li>
                            čuvanje ključa u sertifikovanom sefu (po pismenom
                            nalogu vlasnika, ključ može biti dostupan
                            prijateljima, gostima i rođacima)
                          </li>
                          <ul>
                            <li>
                              <strong>10€ mjesečno</strong>
                            </li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-4"
                      aria-expanded="false"
                    >
                      Dodatne usluge za klijente koji koriste paket osnovnih i
                      premium usluga
                    </h6>
                    <div
                      id="faq-item-2-4"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <ul>
                          <li>
                            poseban izlazak na teren (stan ili kuću) iz bilo
                            kojeg  razloga na izričiti
                            zahtjev vlasnika(jednokratno)
                          </li>
                          <li>
                            organizovanje manjih popravki-angažovanje
                            električara ili vodoinstalatera
                          </li>
                          <li>angažovanje molera za potrebe farbanja</li>
                          <li>čišćenje bazena</li>
                          <li>održavanje zemljišta, rasada, bašte, voćnjaka</li>
                          <li>praćenje video nadzora</li>
                          <li>saradnja sa osiguravajćim kućama</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-5"
                      aria-expanded="false"
                    >
                      Promocija i komercijalizacija nekretnina
                    </h6>
                    <div
                      id="faq-item-2-5"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <ul>
                          <li>Izrada Booking naloga (jednokratno 100€)</li>
                          <li>Ažuriranje kalendara rezervacija i cijena</li>
                          <li>Priprema nekretnine za najam</li>
                          <li>
                            Prodaja kapaciteta (prijem gostiju i naplata
                            smještaja)
                          </li>
                          <li>Komunikacija sa gostima 24/7</li>
                          <li>Odjava gostiju</li>
                          <ul>
                            <li>
                              <strong>
                                Cijena : 20% od ukupne rezervacije
                              </strong>
                            </li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <p>
                    *Paket usluga se kreira i nadopunjuje individualno, u skladu
                    sa klijentovim zahtjevima i željama. Ponuda sa cijenama
                    usluga kreiraće se na temelju dogovorenog paketa usluga,
                    tipa i lokacije nekretnine te željene dinamike njenog
                    obilaska. Preuzimanje nadzora i održavanja nekretnine
                    zaključuje se potpisivanjem Ugovora o nadzoru i održavanju.
                    Ugovor se potpisuje nakon pregleda nekretnine i izrade
                    Zapisnika o zatečenom stanju.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FaqV1;
