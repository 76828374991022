import React, { useEffect } from "react";
import Navbar from "./global-components/navbar-v2";
import PageHeader from "./global-components/page-header";
import AboutV3 from "./section-components/about-v3";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import Faq from "./section-components/faq-v1";

const About_v1 = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Naše usluge" />
      <Faq />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default About_v1;
