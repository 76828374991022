import React, { Component } from "react";
import { Link } from "react-router-dom";

function AboutV3(props) {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="ltn__about-us-area pt-115">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 align-self-center">
            <div className="about-us-img-wrap about-img-left">
              <img
                src={publicUrl + "assets/img/about/about2.jpg"}
                alt="About Us Image"
                w
              />
              <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3 d-none">
                <div className="ltn__video-img ltn__animation-pulse1">
                  <img
                    src={publicUrl + "assets/img/others/8.png"}
                    alt="video popup bg image"
                  />
                  <a
                    className="ltn__video-icon-2 ltn__video-icon-2-border---"
                    href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0"
                    data-rel="lightcase:myCollection"
                  >
                    <i className="fa fa-play" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <div className="about-us-info-wrap">
              <div className="section-title-area ltn__section-title-2--- mb-30">
                <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                  Services
                </h6>
                <h1 className="section-title"> Naše usluge</h1>
              </div>
              <div className="ltn__feature-item ltn__feature-item-3">
                <div className="ltn__feature-icon">
                  <span>
                    <i className="flaticon-key" />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h4>
                    <a href={"/services"}>Nadzor i upravljanje nekretninama</a>
                  </h4>
                </div>
              </div>
              <div className="ltn__feature-item ltn__feature-item-3">
                <div className="ltn__feature-icon">
                  <span>
                    <i className="flaticon-house-4" />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h4>
                    <a href={"/services"}>Održavanje nekretnina</a>
                  </h4>
                </div>
              </div>
              <div className="ltn__feature-item ltn__feature-item-3">
                <div className="ltn__feature-icon">
                  <span>
                    <i className="flaticon-deal" />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h4>
                    <a href={"/services"}>
                      Promocija i komercijalizacija nekretnina
                    </a>
                  </h4>
                </div>
              </div>
              {!props.isInServicesPage && (
                <div className="btn-wrapper animated">
                  <Link
                    to="/services"
                    className="theme-btn-1 btn btn-effect-1 text-uppercase"
                  >
                    Više o uslugama
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutV3;
