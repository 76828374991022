import React from "react";
import Navbar from "./global-components/navbar-v2";
import BannerV8 from "./section-components/banner-v8";
import Aboutv3 from "./section-components/about-v3";
import Aboutv5 from "./section-components/about-v5";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";

const Home = () => {
  return (
    <div>
      <Navbar />
      <BannerV8 />
      <Aboutv3 />
      <Aboutv5 />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default Home;
