import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ContactInfo extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__contact-address-area mb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                <div className="ltn__contact-address-icon">
                  <img
                    src={publicUrl + "assets/img/icons/email.png"}
                    alt="Icon Image"
                  />
                </div>
                <h3>Email Adresa</h3>
                <p>
                  <br />
                  <a href="mailto:becalm.doo@gmail.com">becalm.doo@gmail.com</a>
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                <div className="ltn__contact-address-icon">
                  <img
                    src={publicUrl + "assets/img/icons/phone.png"}
                    alt="Icon Image"
                  />
                </div>
                <h3>Broj telefona</h3>
                <p>
                  <br />
                  <a href="tel:0038269886796">+38269-886-796</a>
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                <div className="ltn__contact-address-icon">
                  <img
                    src={publicUrl + "assets/img/icons/address.png"}
                    alt="Icon Image"
                  />
                </div>
                <h3>Adresa</h3>
                <p>
                  Ulica Rista Lekića 4A <br />
                  Bar, Crna Gora
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactInfo;
