import React from "react";
import { Link } from "react-router-dom";

function AboutV5(props) {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="ltn__about-us-area pb-115 pt-115">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 align-self-center">
            <div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
              <img
                src={publicUrl + "assets/img/about/about1.jpg"}
                alt="Image"
              />
            </div>
          </div>
          <div className="col-lg-7 align-self-center">
            <div className="about-us-info-wrap">
              <div className="section-title-area ltn__section-title-2--- mb-20">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  O nama
                </h6>
                <h1 className="section-title">
                  Facility & property management
                </h1>
                <p>
                  su relativno nove usluge koje se nude na našem tržištu. Obije
                  označavaju upravljanje objektom ili nekretninom u ime vlasnika
                  zato što je on u nemogućnosti to sam raditi ili se pak
                  unajmljivanjem vanjskih saradnika želi rasteretiiti brige I
                  poslova upravljanja.
                </p>
              </div>
              <div className="about-us-info-wrap-inner about-us-info-devide---">
                <p>
                  Samo one nekretnine o kojima se konstantno brine na dnevnom,
                  mjesečnom I godišnjem nivou mogu biti u besprijekornom stanju
                  I kao takve zadržati svoju tržišnu vrijednost.
                </p>
              </div>
              {!props.inAboutPage && (
                <div className="btn-wrapper animated">
                  <Link
                    to="/about"
                    className="theme-btn-1 btn btn-effect-1 text-uppercase"
                  >
                    Više o nama
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutV5;
